import { Redirect, Route } from "react-router-dom";
import React, { Fragment, lazy, Suspense } from "react";
import Loader from "react-loaders";
import { connect } from "react-redux";

import { ToastContainer } from "react-toastify";

import WLAuthRoute from "../../authRoute";

import { request } from "../../helper/request";
import { clear_auth, init_auth } from "../../reducers/Auth";

const Dashboard = lazy(() => import("../../Views/Dashboard"));
const Product = lazy(() => import("../../Views/Produk"));
const Platform = lazy(() => import("../../Views/Platform"));
const Toko = lazy(() => import("../../Views/Toko"));
const Order = lazy(() => import("../../Views/Order"));
const Orders = lazy(() => import("../../Views/Order/kirim"));
const Laporan = lazy(() => import("../../Views/Laporan"));
const Setting = lazy(() => import("../../Views/Setting"));
const UserLogin = lazy(() => import("../../Views/UserPages/Login"));

class AppMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
      auth: this.props.auth,
      seller: JSON.parse(localStorage.getItem("BP-SL-Auth-Scraper")),
      data: [],
    };
  }
  componentDidMount() {
    var Auth = localStorage.getItem("BP-SL-Auth");
    var AuthKey = localStorage.getItem("BP-SL-Auth-Key");
    var AuthUser = localStorage.getItem("BP-SL-Auth-Scraper");
    if (Auth != null && AuthKey != null && AuthUser != null) {
      this.props.init_auth(Auth, AuthKey, JSON.parse(AuthUser));
    }
  }

  request = async (action, data, method) => {
    if (action && data) {
      return request(this.props.api_token, action, data, method);
    }
  };

  render() {
    return (
      <Fragment>
        {/* Dashboards */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-3">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <WLAuthRoute
            path="/dashboard"
            component={Dashboard}
            request={(action, data, method = "GET") =>
              this.request(action, data, method)}
          />
        </Suspense>

        {/* Platform */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-3">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <WLAuthRoute
            path="/platform"
            component={Platform}
            request={(action, data, method = "GET") =>
              this.request(action, data, method)}
          />
        </Suspense>

        {/* Platform */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-3">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <WLAuthRoute
            path="/toko-atau-kategori"
            component={Toko}
            request={(action, data, method = "GET") =>
              this.request(action, data, method)}
          />
        </Suspense>

        {/* Product */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-3">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <WLAuthRoute
            path="/product"
            component={Product}
            request={(action, data, method = "GET") =>
              this.request(action, data, method)}
          />
        </Suspense>

        {/* Order */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-3">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <WLAuthRoute
            path="/order"
            component={Order}
            request={(action, data, method = "GET") =>
              this.request(action, data, method)}
          />
        </Suspense>

        {/* Orders */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-3">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <WLAuthRoute
            path="/orders"
            component={Orders}
            request={(action, data, method = "GET") =>
              this.request(action, data, method)}
          />
        </Suspense>

        {/* Laporan */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-3">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <WLAuthRoute
            path="/laporan-penjualan"
            component={Laporan}
            request={(action, data, method = "GET") =>
              this.request(action, data, method)}
          />
        </Suspense>

        {/* Platform */}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-3">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <WLAuthRoute
            path="/settings"
            component={Setting}
            request={(action, data, method = "GET") =>
              this.request(action, data, method)}
          />
        </Suspense>

        {/* Users Login*/}
        <Suspense
          fallback={
            <div className="loader-container">
              <div className="loader-container-inner">
                <div className="text-center">
                  <Loader type="ball-beat" />
                </div>
                <h6 className="mt-5">
                  Mohon Tunggu...
                  <small>Sedang memuat data</small>
                </h6>
              </div>
            </div>
          }
        >
          <Route
            path="/signin"
            render={(props) => {
              return (
                <UserLogin
                  request={(action, data, method = "GET") =>
                    this.request(action, data, method)}
                  {...props}
                />
              );
            }}
          />
        </Suspense>

        <Route exact path="/" render={() => <Redirect to="/signin" />} />
        <ToastContainer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.Auth.auth,
  seller: state.Auth.seller,
  api_token: state.Auth.api_token,
});

const mapDispatchToProps = (dispatch) => ({
  init_auth: (api_token, auth_key, data) =>
    dispatch(init_auth(api_token, auth_key, data)),
  clear_auth: () => dispatch(clear_auth()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppMain);
