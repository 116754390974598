import qs from "qs";
import { API } from "../config/host";

const request = (apiToken, action, params, method = "GET") => {
  let options = {
    method,
    headers: {
      Accept: "application/json",
      Authorization: apiToken ? `Bearer ${apiToken}` : "",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
  };
  if (
    method.toLocaleLowerCase() === "get" ||
    method.toLocaleLowerCase() === "head"
  ) {
    action += "?" + (new URLSearchParams(params)).toString();
  } else {
    options.body = qs.stringify(params);
  }
  return fetch(API + action, options)
    .then((response) => {
      let success = false;
      if (response.ok) {
        success = true;
      } else if (
        response.status == 401 &&
        window.location.href.split("#")[1] !== "/signin"
      ) {
        localStorage.clear();
        window.location.href = "/#/signin";
      }

      return response.text()
        .then((content) => {
          return content.length > 0
            ? { ...JSON.parse(content), success }
            : { success };
        });
    })
    .catch((error) => {
      console.error(error);
    });
};

export { request };
