export const EB_HOST = "https://belanjapasti.com/";
// export const EB_HOST = "http://103.140.55.46:9090/";
export const APIEB = EB_HOST + "apps-services/";
// export const MAIN_HOST = 'http://localhost:9999/'

export const MAIN_HOST = "https://apiscrap.ebelanja.id/";
// export const MAIN_HOST = "http://103.107.85.79:7001/";

export const API = MAIN_HOST + "v2/";

export const ImageEx = "https://belanjapasti.com/media/image/";
// export const IMG_HOST       = MAIN_HOST+'media/'
export const IMG_HOST = MAIN_HOST + "assets/images/";
export const THUMB_HOST = MAIN_HOST + "media/image/thumb/";
export const DEFAULT_IMG = MAIN_HOST + "media/image/default-img.png";
